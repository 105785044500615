export * from "./material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react";

export const DATASEAT_COLORS = {
  primary: "#65558F",
  components: {
    datetime: {
      rangeSelected: "#E8DEF8",
    },
  },
};
