"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FILTERS_QUERY_MAP = exports.ANALYTICS_QUERY_MAP = exports.cohortWithPrevious = void 0;
const dbTableCampaign_1 = require("../../reporterDb/dbTableCampaign");
const query_1 = require("../../reporterDb/query");
const columnTypes_1 = require("../measuresAsColumns/columnTypes");
const skanCV_1 = require("../measuresAsColumns/skanCV");
const splitsNFilters_1 = require("../splitsNFilters");
const cohortWithPrevious = (cohort) => {
    const lastCohort = "90+";
    const cohortValues = Object.values(dbTableCampaign_1.Cohorts);
    if (cohort === lastCohort)
        return cohortValues;
    const cohortNumber = parseInt(cohort);
    return cohortValues.reduce((acc, c) => {
        if (c === lastCohort || parseInt(c) > cohortNumber)
            return acc;
        return [...acc, c];
    }, []);
};
exports.cohortWithPrevious = cohortWithPrevious;
exports.ANALYTICS_QUERY_MAP = {
    granularity: query_1.GRANULARITY_EXPR,
    SELECT: Object.assign(Object.assign(Object.assign(Object.assign({}, dbTableCampaign_1.CAMPAIGN_SELECT_QUERY_MAP), dbTableCampaign_1.APP_MEASURE_SELECT_QUERY), dbTableCampaign_1.DIMENSION_AS_MEASURE_SELECT_QUERY), { cohorted: ({ eventName, columnType, eventKind, cohort, onException, }) => {
            const cohortCondition = {
                $in: ["EVENT_COHORT", (0, exports.cohortWithPrevious)(cohort)],
            };
            if (columnType === columnTypes_1.ColumnTypes.CohortedAppMeasure) {
                return (0, query_1.withCondition)(dbTableCampaign_1.APP_MEASURE_SELECT_QUERY.appMeasure({ eventName, kind: eventKind }), cohortCondition);
            }
            if (columnType === columnTypes_1.ColumnTypes.CohortedAppMeasureCPA) {
                return (0, query_1.withCondition)(dbTableCampaign_1.APP_MEASURE_SELECT_QUERY.appMeasureCPA(eventName), cohortCondition, ["$div", "args", "1", "expr", "$sum"]);
            }
            onException === null || onException === void 0 ? void 0 : onException(`No query expression for cohorted, eventName:${eventName}, columnType:${columnType}, eventKind:${eventKind}, cohort:${cohort}`);
            return query_1.DUMMY_EXPRESSION;
        }, skanCV: (columnType, whereValues) => {
            const [fineValues, coarseValues] = (0, skanCV_1.separateFineAndCoarseValues)(whereValues);
            const $or = [];
            if (coarseValues === null || coarseValues === void 0 ? void 0 : coarseValues.length) {
                $or.push({
                    $in: [dbTableCampaign_1.DimensionIds.COARSE_CONVERSION_NUMBER, coarseValues],
                });
            }
            if (fineValues === null || fineValues === void 0 ? void 0 : fineValues.length) {
                $or.push({
                    $in: [dbTableCampaign_1.DimensionIds.CONVERSION_VALUE, fineValues],
                });
            }
            const baseQuery = {
                expr: {
                    $sum: {
                        args: ["EVENT_COUNT"],
                        where: [
                            {
                                $or: $or,
                            },
                            {
                                $eq: ["EVENT_NAME", "SKAD_EVENT"],
                            },
                        ],
                    },
                },
            };
            if ([
                columnTypes_1.ColumnTypes.DimensionAsMeasureCPA,
                columnTypes_1.ColumnTypes.DimensionAsMeasurePCPA,
            ].includes(columnType)) {
                const cpaExpr = (0, query_1.divTemplate)((0, query_1.aliasExprTemplate)("$SPENT"), baseQuery);
                if (columnType === columnTypes_1.ColumnTypes.DimensionAsMeasureCPA) {
                    return cpaExpr;
                }
                if (columnType === columnTypes_1.ColumnTypes.DimensionAsMeasurePCPA) {
                    return (0, query_1.mulTemplate)((0, query_1.mulTemplate)(cpaExpr, dbTableCampaign_1.CAMPAIGN_SELECT_QUERY_MAP.skanCoarseRatio), (0, query_1.subTemplate)(1, dbTableCampaign_1.CAMPAIGN_SELECT_QUERY_MAP.skanTier0Ratio));
                }
            }
            return baseQuery;
        } }),
};
function FILTERS_QUERY_MAP(filterCollection) {
    return Object.values(filterCollection)
        .filter(({ value }) => value === null || value === void 0 ? void 0 : value.length)
        .map(({ id, value, valueType }) => {
        if (valueType === "BOOLEAN" || splitsNFilters_1.BOOLEAN_TYPED_FILTERS_MAP[id]) {
            return (0, query_1.WHERE_VALUES_EXPR)(id, value === null || value === void 0 ? void 0 : value.map((v) => (v === "true" ? true : false)));
        }
        return (0, query_1.WHERE_VALUES_EXPR)(id, value);
    });
}
exports.FILTERS_QUERY_MAP = FILTERS_QUERY_MAP;
