type Props = {
  justify?: string;
  align?: string;
  gap?: string;
  children: any;
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  className?: string;
  style?: React.CSSProperties;
  noWrap?: boolean;
};

export const FlexContent = ({
  justify = "",
  align = "",
  children,
  gap = "",
  direction = "row",
  className,
  noWrap,
  style,
}: Props) => {
  return (
    <div
      className={className}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: justify,
        alignItems: align,
        gap,
        flexDirection: direction,
        ...(noWrap ? { wrap: "no-wrap" } : {}),
        ...style,
      }}>
      {children}
    </div>
  );
};
