export const numericRandomId = () => new Date().getTime() + Math.random();
export const guid = function () {
  return ("" + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/1|0/g, function () {
    return (0 | (Math.random() * 16)).toString(16);
  });
};

//A 6-character alphanumeric sequence is pretty enough
//to randomly index a 10k collection (366 = 2.2 billion and 363 = 46656).
export const getRandom6AlphanumericId = () => {
  // generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return "" + firstPart + secondPart;
};
