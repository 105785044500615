import { UrlString } from "model/modelTypes";
import { ANY_PAGE_AUTH_QUERY_PARAMS } from "model/organizations";
import { encodeReportConfig } from "model/report/reportUrlConfig";
import store from "redux/store";
import { IReportStoredInUrl } from ".";

//query params allowed with Analytics Page url
export const ANALYTICS_PAGE_QUERY_PARAMS = {
  autoGenerate: "autoGenerate", //if = 'true', start generating report once config loaded
  reportConfig: "reportConfig", //report Configuration in base64 format
  reportConfigParamVersion: "rcV", //version of how Report Configuration is stored/restored in/from url, default is 1
};
export const DEFAULT_ANALYTICS_PAGE_QUERY_PARAMS = {
  [ANALYTICS_PAGE_QUERY_PARAMS.reportConfigParamVersion]: "1",
};

export function getAnalyticsReportPath(
  campaignId?: string,
  reportConfig?: Partial<IReportStoredInUrl>
): UrlString {
  const { accountId, organizationId } = store.getState().auth;
  const newPath = `/analytics/report${campaignId ? `/${campaignId}` : ""}`;
  const params = new URLSearchParams();
  if (reportConfig) {
    params.append(
      ANALYTICS_PAGE_QUERY_PARAMS.reportConfig,
      encodeReportConfig<Partial<IReportStoredInUrl>>(reportConfig)
    );
  }
  if (organizationId && accountId) {
    params.append(ANY_PAGE_AUTH_QUERY_PARAMS.organizationId, organizationId);
    params.append(ANY_PAGE_AUTH_QUERY_PARAMS.accountId, accountId);
  }

  return `${newPath}?${params.toString()}`;
}
