import FormControlAnchor from "components/FormsUI/errorsAnchored/FormControlAnchor";
import Card from "vendor/material-dashboard-pro-react-v1/components/Card/Card";
import CardBody from "vendor/material-dashboard-pro-react-v1/components/Card/CardBody";
import CardFooter from "vendor/material-dashboard-pro-react-v1/components/Card/CardFooter";
import CardHeader from "vendor/material-dashboard-pro-react-v1/components/Card/CardHeader";
import CardIcon from "vendor/material-dashboard-pro-react-v1/components/Card/CardIcon";
import Button from "vendor/material-dashboard-pro-react-v1/components/CustomButtons/Button";
import CustomDropdown from "vendor/material-dashboard-pro-react-v1/components/CustomDropdown/CustomDropdown";
import VendorCustomInput from "vendor/material-dashboard-pro-react-v1/components/CustomInput/CustomInput";
import GridContainer from "vendor/material-dashboard-pro-react-v1/components/Grid/GridContainer";
import GridItem from "vendor/material-dashboard-pro-react-v1/components/Grid/GridItem";
import Table from "vendor/material-dashboard-pro-react-v1/components/Table/Table";
import Danger from "vendor/material-dashboard-pro-react-v1/components/Typography/Danger";
import Muted, {
  MutedInline,
} from "vendor/material-dashboard-pro-react-v1/components/Typography/Muted.jsx";
import WizardForm from "vendor/material-dashboard-pro-react-v1/components/Wizard/Wizard.jsx";

const CustomInput = ({ children = null, ...props }) => {
  return (
    <>
      <FormControlAnchor id={props.inputProps?.name} />
      <VendorCustomInput {...props}>{children}</VendorCustomInput>
    </>
  );
};

export {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardIcon,
  CustomDropdown,
  CustomInput,
  Danger,
  GridContainer,
  GridItem,
  Muted,
  MutedInline,
  Table,
  WizardForm,
};
