"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DIMENSION_AS_MEASURE_SELECT_QUERY = void 0;
const templates_1 = require("../query/templates");
const customEvent_1 = require("./customEvent");
const queryMap_1 = require("./queryMap");
exports.DIMENSION_AS_MEASURE_SELECT_QUERY = {
    dimensionAsMeasure: ({ columnType, eventName, whereValues = [], kind = customEvent_1.CustomEventKind.count, }) => {
        let dimAsMeasureQuery;
        if (kind === customEvent_1.CustomEventKind.sum) {
            //in future, add 'whereValues' support for sum, if/when needed
            dimAsMeasureQuery = (0, templates_1.sumDimensionTemplate)(eventName);
        }
        else {
            dimAsMeasureQuery = {
                expr: {
                    $sum: {
                        args: ["EVENT_COUNT"],
                        where: [{ $in: [eventName, whereValues] }],
                    },
                },
            };
        }
        if (["DimensionAsMeasureCPA", "DimensionAsMeasurePCPA"].includes(columnType)) {
            const cpaExpr = (0, templates_1.divTemplate)((0, templates_1.aliasExprTemplate)("$SPENT"), dimAsMeasureQuery);
            if (columnType === "DimensionAsMeasureCPA") {
                return cpaExpr;
            }
            if (columnType === "DimensionAsMeasurePCPA") {
                return (0, templates_1.mulTemplate)((0, templates_1.mulTemplate)(cpaExpr, queryMap_1.CAMPAIGN_SELECT_QUERY_MAP.skanCoarseRatio), (0, templates_1.subTemplate)(1, queryMap_1.CAMPAIGN_SELECT_QUERY_MAP.skanTier0Ratio));
            }
        }
        return dimAsMeasureQuery;
    },
};
