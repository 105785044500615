"use strict";
//skanCV is now a combination of 2 dimensions-as-measures:
//CONVERSION_VALUE and COARSE_CONVERSION_NUMBER
//was: just CONVERSION_VALUE
Object.defineProperty(exports, "__esModule", { value: true });
exports.separateFineAndCoarseValues = exports.SEPARATOR = void 0;
const primitive_1 = require("../../../helpers/primitive");
const dbTableCampaign_1 = require("../../../model/reporterDb/dbTableCampaign");
exports.SEPARATOR = "+";
const separateFineAndCoarseValues = (whereValues) => {
    let fineValues = [];
    let coarseValues = [];
    whereValues.forEach((v) => {
        if (typeof v === "string" && v.includes(exports.SEPARATOR)) {
            const value = (0, primitive_1.toNumber)(v.split(exports.SEPARATOR)[1], 0);
            if (v.includes(dbTableCampaign_1.DimensionIds.COARSE_CONVERSION_NUMBER)) {
                coarseValues.push(value);
            }
            else {
                fineValues.push(value);
            }
        }
        else {
            // support value as number
            // for backward compatibility: values for CONVERSION_VALUE were numbers,
            // but since adding dimension id as prefix they became strings
            fineValues.push((0, primitive_1.toNumber)(v, 0));
        }
    });
    return [fineValues, coarseValues];
};
exports.separateFineAndCoarseValues = separateFineAndCoarseValues;
