import { Add } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import CustomFilter from "components/CustomFilter/CustomFilter";
import { makeStyles } from "components/mui-core";
import {
  fuzzyMatch,
  isArrowDownPressed,
  isArrowUpPressed,
  isEnterPressed,
} from "helpers";
import {
  SCROLL_TO_CLASSNAME,
  scrollElementIntoView,
} from "helpers/scrollIntoView";
import { IOrganizationAccount } from "model/organizations";
import React from "react";
import MenuItem from "./MenuItem";
import useNavigationMenuStyles from "./NavigationMenuStyles";

const useStyles = makeStyles({
  formControl: {
    marginBottom: 0,
    paddingTop: 0,
    width: "100%",

    "& .MuiInputBase-input": {
      color: "#fff",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff8", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff", // Solid underline on focus
    },
    "& svg": {
      color: "#fff",
    },
  },
  list: {
    marginLeft: "15px",
    maxHeight: 300,
    paddingRight: "5px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(241, 241, 241, 0.05)",
      // background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
  addNewOrgButton: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#bbb",
    color: "#555",
    padding: "10px",
    textTransform: "uppercase",
    "& span": {
      fontSize: "12px",
      marginLeft: "5px",
      marginTop: "3px",
    },
  },
  noSearchResults: {
    fontSize: "12px",
    fontStyle: "italic",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    pointerEvents: "none",
    marginBottom: "7px",
  },
});

interface IDropdownMenuProps<T> {
  isExpanded: boolean;
  setExpanded: (isExpanded: boolean) => void;
  label: string;
  items?: T[];
  selectedItem?: T;
  onChange: (item: T) => void;
  disabled?: boolean;
  noResultsMessage?: string;
  onAddNewClick?: () => void;
  extraButtons?: React.ReactNode;
}

const DropdownMenu = <T extends IOrganizationAccount>({
  isExpanded,
  setExpanded,
  label = "",
  items = [],
  selectedItem,
  onChange,
  disabled,
  noResultsMessage,
  onAddNewClick,
  extraButtons,
}: IDropdownMenuProps<T>) => {
  const classes: any = useNavigationMenuStyles();
  const ownClasses: any = useStyles();

  const [localSelected, setLocalSelected] = React.useState(selectedItem);

  const [itemsInView, setItemsInView] = React.useState<T[]>([]);

  const onKeyDown = React.useCallback(
    (e) => {
      const selectedIndex = itemsInView.findIndex(
        (i) => i.id === localSelected?.id
      );
      if (isArrowDownPressed(e)) {
        const nextIndex = (selectedIndex + 1) % itemsInView.length;
        const nextItem = itemsInView[nextIndex];
        setLocalSelected(nextItem);
        scrollElementIntoView({
          hash: nextItem.id,
          scrollOptions: { block: "nearest" },
        });
      } else if (isArrowUpPressed(e)) {
        const prevIndex =
          (itemsInView.length + selectedIndex - 1) % itemsInView.length;
        const prevItem = itemsInView[prevIndex];
        setLocalSelected(prevItem);
        scrollElementIntoView({
          hash: prevItem.id,
          scrollOptions: { block: "nearest" },
        });
      } else if (isEnterPressed(e) && !!localSelected) {
        onChange(localSelected);
      }
    },
    [itemsInView, localSelected, onChange]
  );

  const isMultipleChoice = items.length > 1;

  const handleFilter = (val: string) => {
    if (val.length > 1) {
      const filteredItems = items.filter((i) => fuzzyMatch(val, i.name));
      setItemsInView(filteredItems);
    } else {
      setItemsInView(items);
    }
  };

  React.useEffect(() => {
    setLocalSelected(selectedItem);
  }, [selectedItem]);

  React.useEffect(() => {
    if (items.length) {
      setItemsInView(items);
    }
  }, [items]);

  return (
    <>
      <ListItemButton
        data-qa={`select_${label.toLowerCase()}`}
        classes={{
          root: classes.listItem,
        }}
        style={{
          cursor: "pointer",
        }}
        onClick={() => !disabled && setExpanded(!isExpanded)}
        disabled={disabled}>
        <span style={{ textWrap: "wrap" } as React.CSSProperties}>{`${label}: ${
          selectedItem?.name || ""
        }`}</span>

        {!disabled && isMultipleChoice ? (
          isExpanded ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )
        ) : null}
      </ListItemButton>

      {isMultipleChoice && (
        <Collapse
          in={isExpanded}
          unmountOnExit>
          <List
            dense
            style={{ marginLeft: 15 }}>
            <ListItemButton dense={true}>
              <CustomFilter
                onFilter={handleFilter}
                customWrapperClass={ownClasses.formControl}
                clearOnUnmount
                focus={isExpanded}
                onKeyDown={onKeyDown}
                shouldShowCleanIcon
              />
            </ListItemButton>
          </List>
          <List
            dense
            className={ownClasses.list}>
            {itemsInView.map((item) => (
              <MenuItem<T>
                item={item}
                key={item.id}
                id={item.id}
                onChange={onChange}
                selectedItem={localSelected}
                className={SCROLL_TO_CLASSNAME}
              />
            ))}
            {/* Add "+ Add new" menu item here */}
            {!itemsInView.length && !!noResultsMessage?.length && (
              <ListItemButton className={ownClasses.noSearchResults}>
                <span>{noResultsMessage}</span>
              </ListItemButton>
            )}
            {!!onAddNewClick && (
              <ListItemButton
                className={ownClasses.addNewOrgButton}
                dense={true}
                onClick={onAddNewClick}>
                <Add fontSize="small" /> <span>Add new</span>
              </ListItemButton>
            )}
            {extraButtons}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default DropdownMenu;
