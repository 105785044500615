import MonetizationIcon from "@mui/icons-material/AttachMoney";
import TrafficIcon from "@mui/icons-material/AutoGraph";
import BarChartIcon from "@mui/icons-material/BarChart";
import BillingIcon from "@mui/icons-material/CreditCard";
import PredictModelIcon from "@mui/icons-material/Grain";
import OrganizationIcon from "@mui/icons-material/Groups2";
import CreativesIcon from "@mui/icons-material/Image";
import LayersIcon from "@mui/icons-material/Layers";
import AudienceIcon from "@mui/icons-material/People";
import UsersIcon from "@mui/icons-material/PersonAdd";
import OptimizationIcon from "@mui/icons-material/Tune";
import InventoryIcon from "@mui/icons-material/ViewList";
import { Feature } from "configuration";
import { USER_ROLES } from "model/auth";
import React from "react";

export interface INavigationMenuItem {
  icon: React.ReactElement;
  label: string;
  name: string;
  route: string;
  whitelistRoles?: { [role: string]: boolean };
  blacklistRoles?: { [role: string]: boolean };
  sub?: string[];
  alphaOnly?: boolean;
  noAlphaFeatureFlag?: Feature;
  isHidden?: boolean;
}

const navigationMenuItems: INavigationMenuItem[][] = [
  [
    {
      route: "/campaigns",
      name: "campaigns",
      icon: <LayersIcon />,
      label: "Campaigns",
    },
    {
      route: "/analytics",
      name: "analytics",
      icon: <BarChartIcon />,
      label: "Analytics",
    },
    {
      route: "/inventory",
      name: "inventory",
      icon: <InventoryIcon />,
      label: "Inventory",
    },
    {
      route: "/traffic",
      name: "traffic",
      icon: <TrafficIcon />,
      label: "Traffic",
    },
    {
      route: "/trafficRefactor",
      name: "trafficRefactor",
      icon: <TrafficIcon />,
      label: "Traffic Refactor",
      noAlphaFeatureFlag: "trafficRefactor",
    },
    {
      route: "/audiences",
      name: "audiences",
      icon: <AudienceIcon />,
      label: "Audiences",
      blacklistRoles: {
        [USER_ROLES.USER_READ_ONLY]: true,
      },
    },
    {
      route: "/creatives",
      name: "creatives",
      icon: <CreativesIcon />,
      label: "Creatives",
      sub: ["playable"],
    },
    {
      route: "/billing/dashboard",
      name: "billing",
      icon: <BillingIcon />,
      label: "Billing",
    },
    {
      route: "/monetization/apps",
      name: "monetization",
      icon: <MonetizationIcon />,
      label: "Monetization",
      blacklistRoles: {},
      alphaOnly: true,
    },
    {
      route: "/predictModels",
      name: "predictModels",
      icon: <PredictModelIcon />,
      label: "Models",
      whitelistRoles: {
        [USER_ROLES.ADMIN]: true,
        [USER_ROLES.ORGANIZATION_ADMIN]: true,
      },
    },
    {
      route: "/suggested-optimisations",
      name: "suggested-optimisations",
      icon: <OptimizationIcon />,
      label: "Suggested Optimisations",
      whitelistRoles: {
        [USER_ROLES.ADMIN]: true,
      },
    },
  ],
  [
    {
      route: "/users",
      name: "users",
      icon: <UsersIcon />,
      label: "User Management",
      whitelistRoles: {
        [USER_ROLES.ADMIN]: true,
        [USER_ROLES.ORGANIZATION_ADMIN]: true,
      },
    },
    {
      route: "/organization",
      name: "organization",
      icon: <OrganizationIcon />,
      label: "Organization",
      isHidden: true,
    },
  ],
];

export default navigationMenuItems;
