import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import IconButton from "@mui/material/Button";
import MuiSnackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiSnackbarContent from "@mui/material/SnackbarContent";
import cx from "classnames";
import { makeStyles } from "components/mui-core";
import React from "react";
import {
  dangerColor,
  grayColor,
  infoColor,
  warningColor,
} from "vendor/material-dashboard-pro-react-v1/assets/jss/material-dashboard-pro-react";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  success: {
    backgroundColor: infoColor[0],
  },
  error: {
    backgroundColor: dangerColor[2],
  },
  info: {
    backgroundColor: grayColor[0],
  },
  warning: {
    backgroundColor: warningColor[0],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 20, //todo: add theme spacing
  },
  message: {
    display: "flex",
    alignItems: "center",
    wordBreak: "break-word",
  },
}));

export type ISnackbarProps = {
  onClose: () => void;
  isOpen?: boolean;
  message: React.ReactNode;
  variant: "success" | "warning" | "error" | "info";
  ClickAwayListenerProps?: any;
  anchorOrigin?: SnackbarOrigin;
  className?: string;
  contentClassName?: string;
  classes?: {
    content: string;
  };
  duration?: number;
  children?: React.ReactNode;
  noIcon?: boolean;
  sx?;
};
function Snackbar({
  onClose,
  isOpen,
  message,
  variant,
  ClickAwayListenerProps,
  anchorOrigin,
  className,
  contentClassName,
  classes,
  duration,
  noIcon = false,
  sx,
  ...props
}: ISnackbarProps) {
  const ownClasses = useStyles();

  if (!message) return null;

  const Icon = !noIcon && variantIcon[variant];
  if (duration && Number.isInteger(duration)) {
    setTimeout(onClose, duration);
  }

  return (
    <MuiSnackbar
      classes={{ root: className }}
      anchorOrigin={anchorOrigin}
      open={isOpen}
      TransitionProps={{ onExit: onClose }}
      ClickAwayListenerProps={ClickAwayListenerProps}>
      <MuiSnackbarContent
        className={cx(ownClasses.content, ownClasses[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span className={ownClasses.message}>
            {Icon && (
              <Icon className={cx(ownClasses.icon, ownClasses.iconVariant)} />
            )}
            {message}
          </span>
        }
        action={
          onClose && (
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={onClose}>
              <CloseIcon className={ownClasses.icon} />
            </IconButton>
          )
        }
        {...props}
      />
    </MuiSnackbar>
  );
}

Snackbar.defaultProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
};

export default Snackbar;
