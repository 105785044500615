import { Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import clsx from "clsx";
import { FeatureToggles, useNoAlphaFeatureCheck } from "configuration";
import { isAlpha, isDev, isLocal } from "configuration/endpoints";
import { INavigationMenuItem } from "model/routing";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useUsername } from "redux/auth";
import * as userActions from "redux/auth/userActions";
import { infoColor } from "vendor/colors";
import useStyles from "./NavigationMenuStyles";
import UserInfo from "./UserInfo";

export const generateListItemsGroup = (
  itemsGroup: INavigationMenuItem[],
  currentItem: string,
  classes
) => {
  return itemsGroup
    .map((item) => {
      const selected =
        currentItem === item.name ||
        `${currentItem}s` === item.name ||
        item.sub?.includes(currentItem);

      if (item.isHidden) {
        return null;
      }
      if (item.noAlphaFeatureFlag) {
        const showItemByFF = useNoAlphaFeatureCheck(item.noAlphaFeatureFlag);
        if (!showItemByFF) return null;
      }

      return (
        <ListItemButton
          data-qa={`menu_item_${item.name}`}
          key={item.name}
          component={Link}
          to={item.route}
          classes={{
            root: classes.listItem,
            selected: classes.activeListItem,
          }}
          style={selected ? { backgroundColor: infoColor[0] } : {}}
          selected={selected}>
          <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
          <span>{item.label}</span>
        </ListItemButton>
      );
    })
    .filter((item) => !!item);
};

const generateListItems = (allItems, currentItem, classes) => {
  let enabledItems = allItems;
  if (!isAlpha && !isDev && !isLocal) {
    enabledItems = allItems.map((group) =>
      group.filter((item) => !item.alphaOnly)
    );
  }

  return enabledItems
    .map((itemsGroup, index) => {
      const list = generateListItemsGroup(itemsGroup, currentItem, classes);

      if (!list.length) {
        return null;
      }

      return (
        <Fragment key={index}>
          <List className={classes.menuList}>{list}</List>
        </Fragment>
      );
    })
    .filter((item) => !!item);
};

export const FEATURE_TOGGLE_USERS_WHITELIST = [
  "eduardo@dataseat.com",
  "paul@dataseat.com",
  "jason@dataseat.com",
  "rich@dataseat.com",
  "olha@dataseat.com",
  "hanna.beliayeva@verve.com",
  "bernice.mendoza@verve.com",
  "alexei.moltchan@verve.com",
];

function NavigationMenu(props) {
  const classes: any = useStyles();
  const dispatch = useDispatch();
  const username = useUsername();
  const {
    location: { pathname },
  } = props;
  const currentItem = pathname.split("/")[1] || props.rootPath;
  const showFeatureToggle =
    process.env.NODE_ENV === "development" ||
    FEATURE_TOGGLE_USERS_WHITELIST.includes(username);

  return (
    <>
      <Drawer
        id="sidebar"
        variant="permanent"
        classes={{
          root: classes.drawerRoot,
          paper: clsx(
            classes.drawerPaper,
            !props.open && classes.drawerPaperClose
          ),
        }}
        open={props.open}>
        <div className={classes.toolbarIcon}>
          {props.open ? (
            <IconButton onClick={props.collapseNavigation}>
              <MenuIcon className={classes.icon} />
            </IconButton>
          ) : (
            <IconButton onClick={props.expandNavigation}>
              <MenuIcon className={classes.icon} />
            </IconButton>
          )}
        </div>
        <div style={{ overflowY: "auto" }}>
          <List className={classes.menuList}>
            <UserInfo classes={classes} />
          </List>
          {generateListItems(props.menuItems, currentItem, classes)}
          <ListItem
            data-qa="button_logout"
            classes={{
              root: classes.listItem,
            }}
            onClick={() => dispatch(userActions.logout(username))}
            sx={{ display: "flex", justifyContent: "space-between" }}>
            Logout <Logout fontSize="small" />
          </ListItem>
          {showFeatureToggle && (
            <List className={classes.menuList}>
              <FeatureToggles showLabels={props.open} />
            </List>
          )}
          <div className={classes.backgroundImage}></div>
        </div>
      </Drawer>
      <div
        className={
          props.open
            ? classes.drawerWidthPlaceholderWide
            : classes.drawerWidthPlaceholderSmall
        }
      />
    </>
  );
}

export default withRouter(NavigationMenu);
