function FormControlAnchor({ id }) {
  return (
    <div
      style={{
        display: "block",
        height: 1,
        position: "relative",
      }}>
      <div
        id={id}
        style={{
          top: -50,
          position: "absolute",
          width: 1,
          height: 1,
        }}></div>
    </div>
  );
}

export default FormControlAnchor;
