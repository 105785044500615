import Chip from "@mui/material/Chip";
import ListItemButton from "@mui/material/ListItemButton";
import cx from "classnames";
import { makeStyles } from "components/mui-core";
import { IOrganizationAccount } from "model/organizations";
import { grayColor } from "vendor/colors";
import useNavigationMenuStyles from "./NavigationMenuStyles";

const useStyles = makeStyles({
  item: {
    display: "flex",
    justifyContent: "space-between",
    gap: "5px",
  },
});

interface IMenuItemProps<T> {
  item: T;
  selectedItem?: T;
  onChange: (item: T) => void;
  className: string;
  id?: string;
}

const MenuItem = <T extends IOrganizationAccount>({
  item,
  selectedItem,
  onChange,
  className,
  id,
}: IMenuItemProps<T>) => {
  const classes: any = useNavigationMenuStyles();
  const styles = useStyles();

  const isSelected = item.id === selectedItem?.id;
  const style = isSelected
    ? { backgroundColor: "rgba(200, 200, 200, 0.2)" }
    : {};

  return (
    <ListItemButton
      id={id}
      data-qa={item.name}
      dense={true}
      selected={isSelected}
      classes={{
        root: cx(classes.listItem, styles.item, className),
      }}
      onClick={() => onChange(item)}
      style={style}>
      <span>{item.name}</span>

      {!!(item as any)?.archived && (
        <Chip
          label="archived"
          variant="filled"
          color="default"
          size="small"
          sx={{
            backgroundColor: grayColor[0],
            fontSize: "8px",
            color: "white",
            padding: "0px 0px",
            width: "50px",
            height: "15px",
          }}
        />
      )}
    </ListItemButton>
  );
};

export default MenuItem;
