import DownloadIcon from "@mui/icons-material/FileDownload";
import cx from "classnames";
import FormDialog from "components/FormsUI/FormDialog";
import { CircularCenteredLoader } from "components/Loaders";
import { makeStyles } from "components/mui-core";
import {
  bytesToFileSize,
  formatDate,
  monDayAndTimeFormat,
  toTitleCase,
} from "helpers";
import React from "react";
import { useDispatch } from "react-redux";
import { analyticsCsvDownloadsSlice } from "redux/csvDownloads/analyticsCsvDownloadsSlice";
import { inventoryCsvDownloadsSlice } from "redux/csvDownloads/inventoryCsvDownloadsSlice";
import {
  getAnalyticsCsvDownloadsList,
  getInventoryCsvDownloadsList,
  getTrafficCsvDownloadsList,
  isAnalyticsDownloadsModalOpened,
  isInventoryDownloadsModalOpened,
  isTrafficDownloadsModalOpened,
} from "redux/csvDownloads/selectors";
import { trafficCsvDownloadsSlice } from "redux/csvDownloads/trafficCsvDownloadsSlice";
import { ICompletedMetadata, IDownload } from "redux/csvDownloads/types";
import { useSelector } from "redux/hooks";
import loadCsvReportWhenReady from "services/reporter/csvDownload/loadCsvReportWhenReady";
import { dangerColor, primaryColor, warningColor } from "vendor/colors";
import { Muted, MutedInline } from "vendor/components";
import { formatRemainingTime } from "./utils";

const useStyles = makeStyles({
  dialog: {
    minWidth: "60%",
  },
  dialogContent: {
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  trigger: {
    cursor: "pointer",
    padding: 6,
  },
});

type TReportKind = "analytics" | "traffic" | "inventory";
const REDUX: Record<
  TReportKind,
  {
    slice:
      | typeof analyticsCsvDownloadsSlice
      | typeof trafficCsvDownloadsSlice
      | typeof inventoryCsvDownloadsSlice;
    getCsvDownloadsList: typeof getAnalyticsCsvDownloadsList;
    isDownloadsModalOpened: typeof isAnalyticsDownloadsModalOpened;
  }
> = {
  analytics: {
    slice: analyticsCsvDownloadsSlice,
    getCsvDownloadsList: getAnalyticsCsvDownloadsList,
    isDownloadsModalOpened: isAnalyticsDownloadsModalOpened,
  },
  traffic: {
    slice: trafficCsvDownloadsSlice,
    getCsvDownloadsList: getTrafficCsvDownloadsList,
    isDownloadsModalOpened: isTrafficDownloadsModalOpened,
  },
  inventory: {
    slice: inventoryCsvDownloadsSlice,
    getCsvDownloadsList: getInventoryCsvDownloadsList,
    isDownloadsModalOpened: isInventoryDownloadsModalOpened,
  },
};

export function DownloadsModalTrigger({
  children,
  className,
  kind,
}: {
  children: React.ReactNode;
  className?: string;
  kind: TReportKind;
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const slice = REDUX[kind].slice;

  return (
    <>
      <div
        className={cx(classes.trigger, className)}
        onClick={() => dispatch(slice.actions.toggleModal(true))}>
        {children}
      </div>
    </>
  );
}

function DownloadDetail({
  item,
  kind,
}: {
  item: IDownload;
  kind: TReportKind;
}) {
  const dispatch = useDispatch();
  const slice = REDUX[kind].slice;
  const resumeDownload = () => {
    dispatch(
      slice.actions.resumeDownload({
        id: item.id,
      })
    );

    loadCsvReportWhenReady({
      userFriendlyFilename: item.userFriendlyFilename ?? item.fileName,
      reportUrl: item.fileUrl,
      reportFilename: item.fileName,
      errorLogUrl: item.errorLogUrl,
      errorLogFilename: item.errorLogFilename,
    })
      .then((metadata: ICompletedMetadata) => {
        dispatch(
          slice.actions.completeDownload({
            id: item.id,
            metadata,
          })
        );
      })
      .catch(() => {
        dispatch(
          slice.actions.failDownload({
            id: item.id,
          })
        );
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div>
        <MutedInline>File name: </MutedInline>
        {"  "}
        {item.userFriendlyFilename ?? item.fileName}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
        <div>
          <MutedInline>Started on:</MutedInline>
          {"  "}
          {formatDate(new Date(item.addedDatetime), monDayAndTimeFormat)}
        </div>
        {item.completedDatetime && (
          <div>
            <MutedInline>Completed on:</MutedInline>
            {"  "}
            {formatDate(new Date(item.completedDatetime), monDayAndTimeFormat)}
          </div>
        )}
        {item.completedMetadata?.contentLength && (
          <div>
            <MutedInline>File size: </MutedInline>
            {"  "}
            {bytesToFileSize(item.completedMetadata?.contentLength)}
          </div>
        )}

        {!!item.estimatedTime && item.status === "loading" && (
          <div>
            <MutedInline>
              Estimated completition{" "}
              {formatRemainingTime(
                new Date(item.addedDatetime),
                item.estimatedTime
              )}
            </MutedInline>
          </div>
        )}
      </div>
      {item.status === "loading" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: primaryColor[0],
            gap: 8,
          }}>
          Preparing file for download
          <div style={{ width: 20 }}>
            <CircularCenteredLoader size={18} />
          </div>
        </div>
      ) : item.status === "completed" ? (
        <a
          href={item.fileUrl}
          download={item.userFriendlyFilename ?? item.fileName}
          style={{
            all: "unset",
            color: primaryColor[0],
            cursor: "pointer",
            marginLeft: -4,
          }}>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <DownloadIcon style={{ fontSize: 24 }} />
            Click to download
          </div>
        </a>
      ) : item.status === "paused" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: warningColor[0],
            gap: 8,
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              cursor: "pointer",
            }}
            onClick={resumeDownload}>
            <DownloadIcon style={{ fontSize: 24 }} />
            Resume download
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: dangerColor[0],
            gap: 8,
          }}>
          {item.errorLogUrl ? (
            <a
              href={item.errorLogUrl}
              download={item.userFriendlyErrorLogFilename}
              style={{
                all: "unset",
                color: dangerColor[0],
                cursor: "pointer",
                marginLeft: -4,
              }}>
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <DownloadIcon style={{ fontSize: 24 }} />
                Click to download error log
              </div>
            </a>
          ) : (
            <span>File download failed</span>
          )}
        </div>
      )}
    </div>
  );
}

function DownloadsModal({ kind }: { kind: TReportKind }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const slice = REDUX[kind].slice;
  const getCsvDownloadsList = REDUX[kind].getCsvDownloadsList;
  React.useEffect(() => {
    dispatch(slice.actions.loadList());
  }, []);
  const list = useSelector(getCsvDownloadsList);

  return (
    <FormDialog
      isOpen
      onClose={() => dispatch(slice.actions.toggleModal(false))}
      classes={{ root: classes.dialog }}
      cancelButtonLabel="Close"
      dialogTitle={
        <span>
          {toTitleCase(kind)} CSV Downloads <Muted>in last 48 hours</Muted>
        </span>
      }>
      <div className={classes.dialogContent}>
        {list?.length ? (
          list.map((item, index) => (
            <DownloadDetail
              key={index}
              item={item}
              kind={kind}
            />
          ))
        ) : (
          <Muted>No downloads in last 48 hours..</Muted>
        )}
      </div>
    </FormDialog>
  );
}
function CsvDownloadsDialog({ kind }: { kind: TReportKind }) {
  const isDownloadsModalOpened = REDUX[kind].isDownloadsModalOpened;
  const isModalOpened = useSelector(isDownloadsModalOpened);

  return <>{isModalOpened && <DownloadsModal kind={kind} />}</>;
}
export default CsvDownloadsDialog;
