"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnTypes = void 0;
var ColumnTypes;
(function (ColumnTypes) {
    ColumnTypes["Dimension"] = "Dimension";
    ColumnTypes["Event"] = "Event";
    ColumnTypes["Metric"] = "Metric";
    ColumnTypes["Cohorted"] = "Cohorted";
    ColumnTypes["AppMeasure"] = "AppMeasure";
    ColumnTypes["AppMeasureCPA"] = "AppMeasureCPA";
    ColumnTypes["CohortedAppMeasure"] = "CohortedAppMeasure";
    ColumnTypes["CohortedAppMeasureCPA"] = "CohortedAppMeasureCPA";
    ColumnTypes["DimensionAsMeasure"] = "DimensionAsMeasure";
    ColumnTypes["DimensionAsMeasureCPA"] = "DimensionAsMeasureCPA";
    ColumnTypes["DimensionAsMeasurePCPA"] = "DimensionAsMeasurePCPA";
})(ColumnTypes = exports.ColumnTypes || (exports.ColumnTypes = {}));
