import { safelyParseJson } from "helpers";
import LZString from "lz-string";
import { Base64String } from "model/modelTypes";

export function encodeReportConfig<T>(rc: Partial<T>): Base64String {
  return window.btoa(JSON.stringify(rc));
}
export function decodeReportConfig<T>(s: Base64String): Partial<T> {
  return safelyParseJson(window.atob(s));
}

export function encodeCompressReportConfig<T>(rc: Partial<T>): Base64String {
  return LZString.compressToBase64(JSON.stringify(rc));
}
export function decodeDecompressReportConfig<T>(s: Base64String): Partial<T> {
  return safelyParseJson(LZString.decompressFromBase64(s));
}
